import React, { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import ScoresContent from "../components/scores-content/scores-content"
import ScoresContent2 from "../components/scores-content/scores-content2"

import {
  ScoresData,
  MoreCompany,
} from "../components/scores-content/score-content-data"

// import { StaticImage } from "gatsby-plugin-image"
//images

// const scoresdatamap =

const NuQScores = ({ location }) => {
  // const [showScores, setShowScores] = useState("")
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [selectedProduct1, setSelectedProduct1] = useState(null)
  // const [showScores1, setShowScores1] = useState(false)
  console.log("ScoresData", ScoresData)

  return (
    <>
      <Layout location={location}>
        <Seo title="NuQ Scores" />
        <section
          className="page-content nuq-scores-wrapper"
          // style={{ paddingTop: "8rem" }}
        >
          <article>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h2 className="title text-d-cyan pb-0">
                    NuQ <span>Scores</span>
                  </h2>
                  {/* <h3 className="f-300 pb-0 mb-4">
                    Our goal is to provide instant
                    <span className="highlighted-text ml-2">NuQ</span> scores
                    for both restaurants’ and home cooked meals with a simple
                    voice command.
                  </h3> */}
                  <p className="lead mt-4">
                    Our goal is to provide instant
                    <span className="highlighted-text ml-2">NuQ</span> scores
                    for both restaurants’ and home-cooked meals with a simple
                    voice command.
                  </p>
                  {/* <p className="lead">
                    We have analyzed several thousand meals at over 120,000
                    locations in the USA and Canada, here are a few samples.
                  </p> */}
                  {/* <p className="lead mb-5">
                    <span className="highlighted-text mr-1">NuQ</span> Scores
                    are calculated based on the nutrition information provided
                    by each respective brand's own website.
                  </p> */}
                  <p className="lead">
                    <span className="highlighted-text mr-1">NuQ</span> Scores
                    displayed in each level are calculated based on the
                    nutrition information provided by each respective brand’s
                    website;
                  </p>
                  <p className="lead">
                    We have analyzed several thousand meals at over 120,000
                    locations in the USA and Canada. Below are examples.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="container desktop-score">
              <div className="row h-auto">
                {ScoresData.slice(0, 3).map(company => (
                  <div
                    className="col-lg-4 mt-lg-5 mt-md-5 mt-5 text-center company-product-wrapper h-auto"
                    key={company.id}
                  >
                    <div
                      className="company-product-box h-100"
                      style={{
                        // background: "green",
                        borderRadius: "20px",
                        background: `${
                          selectedProduct?.id !== company.id
                            ? "transparent"
                            : "#fffeeb"
                        } `,
                        border: `${
                          selectedProduct?.id !== company.id
                            ? "1px solid rgba(0, 82, 73, 0.3)"
                            : "none"
                        }`,
                      }}
                    >
                      <img
                        className="about-avocado img-fluid"
                        src={company.companyImg}
                        alt="Avocado"
                        style={{ height: "15vh", padding: "0 5rem" }}
                      />
                      <div className="d-flex justify-content-between flex-column">
                        <h3 className="company-name mt-4 mb-0">
                          {company.companyName}
                        </h3>
                        <p className="score-description my-4">
                          {company.companyDescription}
                        </p>
                        <button
                          onClick={() =>
                            setSelectedProduct(prevState =>
                              prevState?.id === company.id ? null : company
                            )
                          }
                          className={`score-btn ${
                            selectedProduct?.id === company.id
                              ? "show-content"
                              : "default-view"
                          }`}
                          style={{
                            //   padding: "0.5rem 2.5rem",
                            width: "10rem",
                            borderRadius: "25px",
                            color: "#007a6e",
                            background: `${
                              selectedProduct?.id !== company.id
                                ? "transparent"
                                : "#fdfbc3"
                            }`,
                            border: `${
                              selectedProduct?.id !== company.id
                                ? "2px solid #007a6e"
                                : "none"
                            }`,
                          }}
                        >
                          {selectedProduct?.id !== company.id
                            ? "See Scores"
                            : "Close"}
                          <svg
                            className="d-none"
                            xmlns="http://www.w3.org/2000/svg"
                            width="15.827"
                            height="28.047"
                            viewBox="0 0 15.827 28.047"
                          >
                            <g
                              id="Component_43_1"
                              data-name="Component 43 – 1"
                              transform="translate(2 2)"
                            >
                              <g
                                id="Component_42"
                                data-name="Component 42"
                                transform="translate(16.18 -8.85) rotate(90)"
                              >
                                <path
                                  id="Union_26"
                                  data-name="Union 26"
                                  d="M12.024,0,24.047,11.827Zm0,0L0,11.827Z"
                                  transform="translate(8.85 4.353)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-linejoin="round"
                                  stroke-width="4"
                                />
                              </g>
                            </g>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
                {selectedProduct && <ScoresContent company={selectedProduct} />}
                {ScoresData.slice(3, 6).map(company => (
                  <div
                    className="col-lg-4 mt-lg-5 mt-md-5 mt-5 text-center company-product-wrapper h-auto"
                    key={company.id}
                  >
                    <div
                      className="company-product-box d-flex flex-column align-items-center justify-content-between h-100"
                      style={{
                        // background: "green",
                        borderRadius: "20px",
                        background: `${
                          selectedProduct1?.id !== company.id
                            ? "transparent"
                            : "#fffeeb"
                        } `,
                        border: `${
                          selectedProduct1?.id !== company.id
                            ? "1px solid rgba(0, 82, 73, 0.3)"
                            : "none"
                        }`,
                      }}
                    >
                      <img
                        className="about-avocado img-fluid"
                        src={company.companyImg}
                        alt="Avocado"
                        style={{
                          height: "15vh",
                          padding: "0 5rem",
                          objectFit: "contain",
                        }}
                      />
                      <div className="d-flex justify-content-between flex-column">
                        <h3 className="company-name mt-4 mb-0">
                          {company.companyName}
                        </h3>
                        <p className="score-description my-4">
                          {company.companyDescription}
                        </p>
                        <button
                          onClick={() =>
                            setSelectedProduct1(prevState =>
                              prevState?.id === company.id ? null : company
                            )
                          }
                          className={`score-btn ${
                            selectedProduct1?.id === company.id
                              ? "show-content"
                              : "default-view"
                          }`}
                          style={{
                            //   padding: "0.5rem 2.5rem",
                            width: "10rem",
                            borderRadius: "25px",
                            color: "#007a6e",
                            background: `${
                              selectedProduct1?.id !== company.id
                                ? "transparent"
                                : "#fdfbc3"
                            }`,
                            border: `${
                              selectedProduct1?.id !== company.id
                                ? "2px solid #007a6e"
                                : "none"
                            }`,
                          }}
                        >
                          {selectedProduct1?.id !== company.id
                            ? "See Scores"
                            : "Close"}
                          <svg
                            className="d-none"
                            xmlns="http://www.w3.org/2000/svg"
                            width="15.827"
                            height="28.047"
                            viewBox="0 0 15.827 28.047"
                          >
                            <g
                              id="Component_43_1"
                              data-name="Component 43 – 1"
                              transform="translate(2 2)"
                            >
                              <g
                                id="Component_42"
                                data-name="Component 42"
                                transform="translate(16.18 -8.85) rotate(90)"
                              >
                                <path
                                  id="Union_26"
                                  data-name="Union 26"
                                  d="M12.024,0,24.047,11.827Zm0,0L0,11.827Z"
                                  transform="translate(8.85 4.353)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-linejoin="round"
                                  stroke-width="4"
                                />
                              </g>
                            </g>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
                {selectedProduct1 && (
                  <ScoresContent company={selectedProduct1} />
                )}
              </div>
            </div>
            <div className="container mobile-score d-none">
              <div className="row h-auto">
                {ScoresData.map(company => (
                  <div
                    className="col-lg-4 mt-lg-5 mt-md-5 mt-5 text-center company-product-wrapper h-auto"
                    key={company.id}
                  >
                    <div
                      className="company-product-box d-flex flex-column align-items-center justify-content-between h-100"
                      style={{
                        // background: "green",
                        borderRadius: "20px",
                        background: `${
                          selectedProduct1?.id !== company.id
                            ? "transparent"
                            : "#fffeeb"
                        } `,
                        border: `${
                          selectedProduct1?.id !== company.id
                            ? "1px solid rgba(0, 82, 73, 0.3)"
                            : "none"
                        }`,
                      }}
                    >
                      <img
                        className="about-avocado img-fluid"
                        src={company.companyImg}
                        alt="Avocado"
                        style={{
                          height: "15vh",
                          padding: "0 5rem",
                          objectFit: "contain",
                        }}
                      />
                      <div className="d-flex justify-content-between flex-column">
                        <h3 className="company-name mt-4 mb-0">
                          {company.companyName}
                        </h3>
                        <p className="score-description my-4">
                          {company.companyDescription}
                        </p>
                        <a
                          href="#scorebodys"
                          onClick={() =>
                            setSelectedProduct1(prevState =>
                              prevState?.id === company.id ? null : company
                            )
                          }
                          className={`score-btn ${
                            selectedProduct1?.id === company.id
                              ? "show-content"
                              : "default-view"
                          }`}
                          style={{
                            //   padding: "0.5rem 2.5rem",
                            width: "10rem",
                            borderRadius: "25px",
                            color: "#007a6e",
                            background: `${
                              selectedProduct1?.id !== company.id
                                ? "transparent"
                                : "#fdfbc3"
                            }`,
                            border: `${
                              selectedProduct1?.id !== company.id
                                ? "2px solid #007a6e"
                                : "none"
                            }`,
                          }}
                        >
                          {selectedProduct1?.id !== company.id
                            ? "See Scores"
                            : "Close"}
                          <svg
                            className="d-none"
                            xmlns="http://www.w3.org/2000/svg"
                            width="15.827"
                            height="28.047"
                            viewBox="0 0 15.827 28.047"
                          >
                            <g
                              id="Component_43_1"
                              data-name="Component 43 – 1"
                              transform="translate(2 2)"
                            >
                              <g
                                id="Component_42"
                                data-name="Component 42"
                                transform="translate(16.18 -8.85) rotate(90)"
                              >
                                <path
                                  id="Union_26"
                                  data-name="Union 26"
                                  d="M12.024,0,24.047,11.827Zm0,0L0,11.827Z"
                                  transform="translate(8.85 4.353)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-linejoin="round"
                                  stroke-width="4"
                                />
                              </g>
                            </g>
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
                {selectedProduct1 && (
                  <ScoresContent2 company={selectedProduct1} />
                )}
              </div>
            </div>
          </article>
          <article style={{ marginTop: "5rem" }} className="mb-5">
            <div className="container">
              <div className="row h-auto">
                <div className="col-lg-12">
                  <h3
                    style={{
                      fontFamily: "Lato",
                      fontWeight: "bold",
                      fontSize: "32px",
                      color: "#012925",
                      lineHeight: "47px",
                      letterSpacing: "0.004em",
                    }}
                  >
                    + Many more
                  </h3>
                </div>
                {MoreCompany.map(morecompany => (
                  <div
                    className="col-lg-2 col-md-4 col-6 h-auto mt-4"
                    // style={{ border: "2px solid green" }}
                  >
                    <div
                      className={`h-100 ${
                        morecompany.fullContent
                          ? "full-width-img"
                          : "normal-width-img"
                      }`}
                    >
                      <div className="d-flex align-items-center justify-content-center h-100">
                        <img
                          src={morecompany.img}
                          alt={morecompany.alt}
                          className="w-100 img-fluid h-100"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </article>
        </section>
      </Layout>
    </>
  )
}

export default NuQScores
