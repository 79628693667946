import ImgPlacholder from "../../images/home2/hero-avocado.png"
import BurgerKingLogo from "../../images/scores-img/burger-king.png"
import McDonaldsLogo from "../../images/scores-img/mcdonalds.png"
import InnoutLogo from "../../images/scores-img/in-n-out.png"
import BurgerPlaceholder1 from "../../images/scores-img/burger-king-wooper.png"
import BurgerPlaceholder2 from "../../images/scores-img/burgerplaceholder2.png"
import burgerimpossible from "../../images/scores-img/Burger-King-Impossible-Wooper.png"
import CheesecakeTransparent from "../../images/cheesecaketransparent.png"
import OutbackTransparent from "../../images/scores-img/outbacktransparent.png"
import bigmac from "../../images/scores-img/McDonalds_Big_Mac.png"
import mcchicken from "../../images/scores-img/McDonalds_McChicken.png"
import double from "../../images/scores-img/double-double.jpg"
import cheeseburger from "../../images/scores-img/cheeseburger.png"
import olivegardenchicken from "../../images/scores-img/Olive_Garden_Chicken_Parmigiana.png"
import oliveshrimp from "../../images/scores-img/Olive_Garden_Shrimp_Scampi.png"
import alice from "../../images/scores-img/alice.png"
import victoria from "../../images/scores-img/victorias.png"
import skinnylicious from "../../images/scores-img/skinnylicious.png"
import grilled from "../../images/scores-img/grilled.png"

// for more company scores
import pfChanangs from "../../images/scores-img/pfchangs.png"
import chipotle from "../../images/scores-img/chipotlelogo.png"
import denny from "../../images/scores-img/dennyslogo.png"
import deltaco from "../../images/scores-img/deltacologo.png"
import olivegarden from "../../images/scores-img/olivegardenlogo.png"
import starbucks from "../../images/scores-img/starbuckslogo.png"
import whataburger from "../../images/scores-img/whataburger.png"
import whitecastle from "../../images/scores-img/whitecastle.png"
import applebees from "../../images/scores-img/applebees.png"
import bigboy from "../../images/scores-img/bigboy.png"
import buffalowings from "../../images/scores-img/bufallowings.png"
import burgerking from "../../images/scores-img/burgerking.jpeg"
import carls from "../../images/scores-img/carls.png"
import chickfila from "../../images/scores-img/chickfila.png"
import dominos from "../../images/scores-img/dominos.png"
import elpolloloco from "../../images/scores-img/elpolloloco.png"
import fiveguys from "../../images/scores-img/fiveguys.jpeg"
import innout from "../../images/scores-img/in-n-out.png"
import kfc from "../../images/scores-img/kfc.png"
import jackinthebox from "../../images/scores-img/jackinthebox.png"
import mcdonalds from "../../images/scores-img/mcdonalds.png"
import outback from "../../images/scores-img/outback.jpeg"
import papajohns from "../../images/scores-img/papa-johns-pizza.svg"
import subway from "../../images/scores-img/subway.png"
import tacobell from "../../images/scores-img/tacobell.jpg"
import tgifridays from "../../images/scores-img/tgifridays.png"
import thecheesecake from "../../images/scores-img/thecheesecake.png"

export const ScoresData = [
  {
    id: 1,
    companyImg: BurgerKingLogo,
    companyName: "Burger King",
    // companyDescription: "Our NuQ scores for the most popJar Burger King meals",
    products: [
      {
        productImg: BurgerPlaceholder1,
        productReview: "Good",
        productCode: "NuQ 105",
        productName: "Whopper",
        productInfo: [
          {
            tag: "Calories",
            value: 2000,
          },
          {
            tag: "Carbs",
            value: "275 g",
          },
          {
            tag: "Cholesterol",
            value: "300 mg",
          },
          {
            tag: "Total Fat",
            value: "80 g",
          },
          {
            tag: "Saturated Fat",
            value: "20 g",
          },
          {
            tag: "Trans Fat",
            value: "2 g",
          },
          {
            tag: "Dietary Fiber",
            value: "28 g",
          },
          {
            tag: "Total Sugars",
            value: "50 g",
          },
          {
            tag: "Added Sugars",
            value: "<50 g",
          },
          {
            tag: "Protein",
            value: "50 g",
          },
        ],
      },
      {
        productImg: burgerimpossible,
        productReview: "Good",
        productCode: "NuQ 114",
        productName: "Impossible Burger",
        productInfo: [
          {
            tag: "Calories",
            value: 2000,
          },
          {
            tag: "Carbs",
            value: "275 g",
          },
          {
            tag: "Cholesterol",
            value: "300 mg",
          },
          {
            tag: "Total Fat",
            value: "80 g",
          },
          {
            tag: "Saturated Fat",
            value: "20 g",
          },
          {
            tag: "Trans Fat",
            value: "2 g",
          },
          {
            tag: "Dietary Fiber",
            value: "28 g",
          },
          {
            tag: "Total Sugars",
            value: "50 g",
          },
          {
            tag: "Added Sugars",
            value: "<50 g",
          },
          {
            tag: "Protein",
            value: "50 g",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    companyImg: McDonaldsLogo,
    companyName: "McDonalds",
    // companyDescription: "Our NuQ scores for the most popJar Burger King meals",
    products: [
      {
        productImg: bigmac,
        productReview: "Good",
        productCode: "NuQ 114",
        productName: "Big Mac",
        productInfo: [
          {
            tag: "Calories",
            value: 2000,
          },
          {
            tag: "Carbs",
            value: "275 g",
          },
          {
            tag: "Cholesterol",
            value: "300 mg",
          },
          {
            tag: "Total Fat",
            value: "80 g",
          },
          {
            tag: "Saturated Fat",
            value: "20 g",
          },
          {
            tag: "Trans Fat",
            value: "2 g",
          },
          {
            tag: "Dietary Fiber",
            value: "28 g",
          },
          {
            tag: "Total Sugars",
            value: "50 g",
          },
          {
            tag: "Added Sugars",
            value: "<50 g",
          },
          {
            tag: "Protein",
            value: "50 g",
          },
        ],
      },
      {
        productImg: mcchicken,
        productReview: "Very Good",
        productCode: "NuQ 134",
        productName: "McChicken",
        productInfo: [
          {
            tag: "Calories",
            value: 2000,
          },
          {
            tag: "Carbs",
            value: "275 g",
          },
          {
            tag: "Cholesterol",
            value: "300 mg",
          },
          {
            tag: "Total Fat",
            value: "80 g",
          },
          {
            tag: "Saturated Fat",
            value: "20 g",
          },
          {
            tag: "Trans Fat",
            value: "2 g",
          },
          {
            tag: "Dietary Fiber",
            value: "28 g",
          },
          {
            tag: "Total Sugars",
            value: "50 g",
          },
          {
            tag: "Added Sugars",
            value: "<50 g",
          },
          {
            tag: "Protein",
            value: "50 g",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    companyImg: InnoutLogo,
    companyName: "IN-N-OUT Burger",
    // companyDescription: "Our NuQ scores for the most popJar Burger King meals",
    products: [
      {
        productImg: double,
        productReview: "Good",
        productCode: "NuQ 102",
        productName: "Double Double",
        productInfo: [
          {
            tag: "Calories",
            value: 2000,
          },
          {
            tag: "Carbs",
            value: "275 g",
          },
          {
            tag: "Cholesterol",
            value: "300 mg",
          },
          {
            tag: "TotalFat",
            value: "80 g",
          },
          {
            tag: "SaturatedFat",
            value: "20 g",
          },
          {
            tag: "TransFat",
            value: "2 g",
          },
          {
            tag: "DietaryFiber",
            value: "28 g",
          },
          {
            tag: "TotalSugars",
            value: "50 g",
          },
          {
            tag: "AddedSugars",
            value: "<50 g",
          },
          {
            tag: "Protein",
            value: "50 g",
          },
        ],
      },
      {
        productImg: cheeseburger,
        productReview: "Good",
        productCode: "NuQ 115",
        productName: "Cheeseburger w/Onions",
        productInfo: [
          {
            tag: "Calories",
            value: 2000,
          },
          {
            tag: "Carbs",
            value: "275 g",
          },
          {
            tag: "Cholesterol",
            value: "300 mg",
          },
          {
            tag: "TotalFat",
            value: "80 g",
          },
          {
            tag: "SaturatedFat",
            value: "20 g",
          },
          {
            tag: "TransFat",
            value: "2 g",
          },
          {
            tag: "DietaryFiber",
            value: "28 g",
          },
          {
            tag: "TotalSugars",
            value: "50 g",
          },
          {
            tag: "AddedSugars",
            value: "<50 g",
          },
          {
            tag: "Protein",
            value: "50 g",
          },
        ],
      },
    ],
  },
  {
    id: 4,
    companyImg: olivegarden,
    companyName: "Olive Garden",
    // companyDescription: "Our NuQ scores for the most popJar Burger King meals",
    products: [
      {
        productImg: olivegardenchicken,
        productReview: "Good",
        productCode: "NuQ 115",
        productName: "Chicken Parmigiana",
        productInfo: [
          {
            tag: "Calories",
            value: 2000,
          },
          {
            tag: "Carbs",
            value: "275 g",
          },
          {
            tag: "Cholesterol",
            value: "300 mg",
          },
          {
            tag: "Total Fat",
            value: "80 g",
          },
          {
            tag: "Saturated Fat",
            value: "20 g",
          },
          {
            tag: "Trans Fat",
            value: "2 g",
          },
          {
            tag: "Dietary Fiber",
            value: "28 g",
          },
          {
            tag: "Total Sugars",
            value: "50 g",
          },
          {
            tag: "Added Sugars",
            value: "<50 g",
          },
          {
            tag: "Protein",
            value: "50 g",
          },
        ],
      },
      {
        productImg: oliveshrimp,
        productReview: "Very Good",
        productCode: "NuQ 134",
        productName: "Shrimp Scampi (Lunch)",
        productInfo: [
          {
            tag: "Calories",
            value: 2000,
          },
          {
            tag: "Carbs",
            value: "275 g",
          },
          {
            tag: "Cholesterol",
            value: "300 mg",
          },
          {
            tag: "Total Fat",
            value: "80 g",
          },
          {
            tag: "Saturated Fat",
            value: "20 g",
          },
          {
            tag: "Trans Fat",
            value: "2 g",
          },
          {
            tag: "Dietary Fiber",
            value: "28 g",
          },
          {
            tag: "Total Sugars",
            value: "50 g",
          },
          {
            tag: "Added Sugars",
            value: "<50 g",
          },
          {
            tag: "Protein",
            value: "50 g",
          },
        ],
      },
    ],
  },
  {
    id: 5,
    companyImg: OutbackTransparent,
    companyName: "Outback Steakhouse",
    // companyDescription: "Our NuQ scores for the most popJar Burger King meals",
    products: [
      {
        productImg: alice,
        productReview: "Improving",
        productCode: "NuQ 79",
        productName: "Alice Springs Chicken Quesadila ",
        productInfo: [
          {
            tag: "Calories",
            value: 2000,
          },
          {
            tag: "Carbs",
            value: "275 g",
          },
          {
            tag: "Cholesterol",
            value: "300 mg",
          },
          {
            tag: "Total Fat",
            value: "80 g",
          },
          {
            tag: "Saturated Fat",
            value: "20 g",
          },
          {
            tag: "Trans Fat",
            value: "2 g",
          },
          {
            tag: "Dietary Fiber",
            value: "28 g",
          },
          {
            tag: "Total Sugars",
            value: "50 g",
          },
          {
            tag: "Added Sugars",
            value: "<50 g",
          },
          {
            tag: "Protein",
            value: "50 g",
          },
        ],
      },
      {
        productImg: victoria,
        productReview: "Average",
        productCode: "NuQ 84",
        productName: "Victoria' Fillet Mignon (10oz)",
        productInfo: [
          {
            tag: "Calories",
            value: 2000,
          },
          {
            tag: "Carbs",
            value: "275 g",
          },
          {
            tag: "Cholesterol",
            value: "300 mg",
          },
          {
            tag: "Total Fat",
            value: "80 g",
          },
          {
            tag: "Saturated Fat",
            value: "20 g",
          },
          {
            tag: "Trans Fat",
            value: "2 g",
          },
          {
            tag: "Dietary Fiber",
            value: "28 g",
          },
          {
            tag: "Total Sugars",
            value: "50 g",
          },
          {
            tag: "Added Sugars",
            value: "<50 g",
          },
          {
            tag: "Protein",
            value: "50 g",
          },
        ],
      },
    ],
  },
  {
    id: 6,
    companyImg: CheesecakeTransparent,
    companyName: "The Cheesecake Factory",
    // companyDescription: "Our NuQ scores for the most popJar Burger King meals",
    products: [
      {
        productImg: skinnylicious,
        productReview: "Good",
        productCode: "NuQ 119",
        productName: "SkinnyLicious Veggie Burger",
        productInfo: [
          {
            tag: "Calories",
            value: 2000,
          },
          {
            tag: "Carbs",
            value: "275 g",
          },
          {
            tag: "Cholesterol",
            value: "300 mg",
          },
          {
            tag: "Total Fat",
            value: "80 g",
          },
          {
            tag: "Saturated Fat",
            value: "20 g",
          },
          {
            tag: "Trans Fat",
            value: "2 g",
          },
          {
            tag: "Dietary Fiber",
            value: "28 g",
          },
          {
            tag: "Total Sugars",
            value: "50 g",
          },
          {
            tag: "Added Sugars",
            value: "<50 g",
          },
          {
            tag: "Protein",
            value: "50 g",
          },
        ],
      },
      {
        productImg: grilled,
        productReview: "Good",
        productCode: "NuQ 101",
        productName: "Grilled Steak Medallions",
        productInfo: [
          {
            tag: "Calories",
            value: 2000,
          },
          {
            tag: "Carbs",
            value: "275 g",
          },
          {
            tag: "Cholesterol",
            value: "300 mg",
          },
          {
            tag: "Total Fat",
            value: "80 g",
          },
          {
            tag: "Saturated Fat",
            value: "20 g",
          },
          {
            tag: "Trans Fat",
            value: "2 g",
          },
          {
            tag: "Dietary Fiber",
            value: "28 g",
          },
          {
            tag: "Total Sugars",
            value: "50 g",
          },
          {
            tag: "Added Sugars",
            value: "<50 g",
          },
          {
            tag: "Protein",
            value: "50 g",
          },
        ],
      },
    ],
  },
]

export const MoreCompany = [
  {
    img: pfChanangs,
    alt: "P.F.Changs",
    fullContent: "fullContent",
  },
  {
    img: chipotle,
    alt: "Chipotle",
  },
  {
    img: denny,
    alt: "Denny's",
  },
  {
    img: deltaco,
    alt: "Del Taco",
  },
  {
    img: olivegarden,
    alt: "Olive Garden Italian Kitchen",
  },
  {
    img: starbucks,
    alt: "Starbucks",
  },
  {
    img: whataburger,
    alt: "Whataburger",
  },
  {
    img: whitecastle,
    alt: "White Castle",
  },
  {
    img: applebees,
    alt: "Applebees",
  },
  {
    img: bigboy,
    alt: "Big Boy",
  },
  {
    img: buffalowings,
    alt: "Buffalo wings and rings",
  },
  {
    img: burgerking,
    alt: "Burger King",
    fullContent: "fullContent",
  },
  {
    img: carls,
    alt: "Carl's Jr",
  },
  {
    img: chickfila,
    alt: "Chick-Fil-A",
    fullContent: "fullContent",
  },
  {
    img: dominos,
    alt: "Domino's Pizza",
  },
  {
    img: elpolloloco,
    alt: "El Pollo Loco",
  },
  {
    img: fiveguys,
    alt: "Five guys burgers and fries",
  },
  {
    img: innout,
    alt: "In-n-out Burger",
  },
  {
    img: kfc,
    alt: "KFC",
  },
  {
    img: jackinthebox,
    alt: "Jack in the box",
  },
  {
    img: mcdonalds,
    alt: "McDonalds",
  },
  {
    img: outback,
    alt: "Outback Steakhouse",
  },
  {
    img: papajohns,
    alt: "Papa John's Pizza",
  },
  {
    img: subway,
    alt: "Subway",
  },
  {
    img: tacobell,
    alt: "Tacobell",
  },
  {
    img: tgifridays,
    alt: "TGIFridays",
  },
  {
    img: thecheesecake,
    alt: "The Cheesecake Factory",
    fullContent: "fullContent",
  },
]
