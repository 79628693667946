import React, { useState } from "react"
// import Testing from "../../pages/nuq37-scores"

// import { StaticImage } from "gatsby-plugin-image"

// import { ScoresData } from "../scores-content/score-content-data"

const ScoresContent = ({ company }) => {
  //   console.log("ScoresData", ScoresData)

  const [selectedProductInfo, setSelectedProductInfo] = useState(null)

  return (
    <>
      <div className="container">
        <div className="row" id="scorebodys">
          {company?.products.map(prodscores => (
            <div className="col-lg-4 h-100 mt-5" key={prodscores.productName}>
              <div style={{ position: "relative" }}>
                {selectedProductInfo &&
                selectedProductInfo.productCode === prodscores.productCode ? (
                  <Info
                    productInfo={selectedProductInfo.productInfo}
                    setSelectedProductInfo={setSelectedProductInfo}
                  />
                ) : (
                  <div className="w-100">
                    {/* <button
                      onClick={() => setSelectedProductInfo(prodscores)}
                      style={{
                        background: "none",
                        border: "none",
                        padding: 0,
                        position: "absolute",
                        top: 0,
                        right: 0,
                      }}
                    >
                      <span
                        style={{
                          // background: "#d0021b",
                          borderRadius: "8px",
                          padding: "0.2rem 1.5rem",
                          // width: "83px",
                          // height: "46px",
                          display: "inline-block",
                        }}
                        className={`trigger-content ${
                          prodscores.productReview === "Bad"
                            ? "bad-review-bg"
                            : prodscores.productReview === "Average"
                            ? "average-review-bg"
                            : prodscores.productReview === "Excellent"
                            ? "excellent-review-bg"
                            : null
                        } `}
                      >
                        {prodscores.productReview}
                      </span>
                    </button> */}
                    <span
                      style={{
                        // background: "#d0021b",
                        borderRadius: "8px",
                        padding: "0.2rem 1.5rem",
                        // width: "83px",
                        // height: "46px",
                        display: "inline-block",
                      }}
                      className={`trigger-content ${
                        prodscores.productReview === "Bad"
                          ? "bad-review-bg"
                          : prodscores.productReview === "Improving"
                          ? "improving-review-bg"
                          : prodscores.productReview === "Good"
                          ? "good-review-bg"
                          : prodscores.productReview === "Very Good"
                          ? "very-good-review-bg"
                          : prodscores.productReview === "Average"
                          ? "average-review-bg"
                          : prodscores.productReview === "Excellent"
                          ? "excellent-review-bg"
                          : null
                      } `}
                    >
                      {prodscores.productReview}
                    </span>
                    <div className="w-100 text-center">
                      <img
                        className="about-avocado img-fluid mx-auto"
                        src={prodscores.productImg}
                        alt="Avocado"
                        //   style={{ height: "20vh" }}
                      />
                    </div>
                  </div>
                )}
                <h4 className="font-weight-bold pt-3 pb-2 mb-0">
                  {prodscores.productCode}
                </h4>
                <p className="mb-0">{prodscores.productName}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

const Info = ({ productInfo, setSelectedProductInfo }) => {
  return (
    <div
      // key={prodscores.productCode}
      style={{
        background: "#fffeeb",
        width: "100%",
        height: "100%",
        borderRadius: "20px",
        padding: "1rem 2rem",
        marginBottom: "1.5rem",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "0.5rem",
          right: "0.5rem",
        }}
      >
        <button
          className="btn p-0"
          onClick={() => setSelectedProductInfo(null)}
        >
          <span
            style={{
              padding: "0.3rem 1rem",
              display: "inline-block",
              // width: "10px",
              position: "absolute",
              top: "0.5rem",
              right: "0.5rem",
              zIndex: 1,
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 24 24"
            >
              <path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" />
            </svg>
          </span>
        </button>
      </div>
      <ul className="mb-0" style={{ padding: 0, listStyleType: "none" }}>
        {productInfo.map(prodInfo => (
          <li>
            <div className="container">
              <div className="row">
                <div className="col-lg-7 pl-0">
                  <p style={{ fontSize: "16px", color: "#1d4d4f" }}>
                    {prodInfo.tag}
                  </p>
                </div>
                <div className="col-lg-5">
                  <p style={{ color: "#007a6e", fontSize: "16px" }}>
                    {prodInfo.value}
                  </p>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ScoresContent
